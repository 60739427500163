import React, { useState, useEffect } from 'react';
import Header from '../../common/Header';
import InteractiveInput from '../../common/InteractiveInput';
import TabBar from '../../common/TabBar';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from '../../../reducers/messageSlice'
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routing/routeConsts';
import { credentialsDefault } from '../../../consts';
import { storageHelper } from '../../../helpers/storageHelper';
import { authHelper } from '../../../api/authHelper';
import ChatList from '../../common/ChatList';
import Modal from '../../common/modals/Modal';
import ScreenshotWarningModal from '../../common/modals/ScreenshotWarningModal';

export default function MessagesPage() {
    const navigate = useNavigate();
    const credentials = useSelector(state => state.message.credentials);
    const dispatch = useDispatch();
    const [number, setNumber] = useState();
    const [pin, setPin] = useState();
    const [showWarning, setShowWarning] = useState(false);
    const canAccessChat = number?.filter(x => x || x === 0) && pin?.filter(x => x || x === 0).length === 5;
    const userHumanId = authHelper.getUserHumanId();

    const enterChat = () => {
        dispatch(setCredentials({ pin: pin.join(""), number: number.join("") }));
        navigate(routes.chat);
    }

    useEffect(() => {
        const storedCredentials = storageHelper.getMessageCredentials();

        if (credentials || storedCredentials) {
          setShowWarning(true);
        }

        if (!credentials && storedCredentials) {
            dispatch(setCredentials(storedCredentials));
            storageHelper.removeMessageCredentials();
            return;
        }
        setNumber(credentials?.number?.split("") || credentialsDefault);
        setPin(credentials?.pin?.split("") || credentialsDefault);
    }, [credentials])

    return (userHumanId ?
        <>
            <Header title={"Messages"} showLanguage={true} />
            <ChatList humanId={userHumanId}/>
            <TabBar />
        </>
        :
        <>
            {showWarning &&
                <Modal
                    onNextClick={() => {
                        setShowWarning(false);
                    }}
                    onCancelClick={() => {
                        setShowWarning(false);
                    }}
                    component={ScreenshotWarningModal}
                    nextText='Okay'
                    cancelText='Cancel'
            />}

            <Header useLogo={true} showLanguage={true} />
            <div className="container messages-page__container tab-bar-predecessor">
                <div className="text_default text_bold text_block">
                  Use this Report Number and Pin to view any Messages related to your report <br/>
                  Check for future messages at <a href="https://www.reportit.com/" target='_blank' className="link-inline">www.reportit.com</a>. Enter the Report Number and PIN code in the messaging tab
                </div>
                <div className="text_default_gray">Enter a Report Number</div>
                <InteractiveInput
                    inputValue={number}
                    onInputValueChange={value => setNumber(value)}
                    uniquePrefix="number"
                />
                <div className="text_default_gray">Enter a Pin</div>
                <InteractiveInput
                    inputValue={pin}
                    onInputValueChange={value => setPin(value)}
                    isExtensible={false}
                    uniquePrefix="pin"
                    onSubmit={canAccessChat ? () => enterChat() : null}
                />

                {canAccessChat &&
                    <div
                        className="button text_default"
                        onClick={() => enterChat()}>Continue</div>
                }

            </div>
            <TabBar />
        </>
    )
}