import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearSucceededResponse } from '../../../../reducers/reportSlice';
import { setCredentials } from '../../../../reducers/messageSlice';
import { routes } from '../../../../routing/routeConsts';
import { languages } from '../../../../consts';
import { automaticRedirectionTime } from '../../../../consts'
import { changeLanguage, getLanguage } from '../../../../helpers/languageHelper';
import { storageHelper } from '../../../../helpers/storageHelper';
import AndroidAppLink from '../../../common/AndroidAppLink';
import IosAppLink from '../../../common/IosAppLink';
import { authHelper } from '../../../../api/authHelper';

export default function SuccessSubmissionPage() {
    const succeededResponse = useSelector(state => state.report.succeededResponse);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const shouldBeTranslated = languages[0].code !== getLanguage().code;
    const userHumanId = authHelper.getUserHumanId();

    useEffect(() => {
        if (!succeededResponse) {
            navigate(routes.home);
        }

        let userInactivityTimer = setTimeout(function () {
            navigate(routes.home);
        }, automaticRedirectionTime);

        let navigationButtons = document.querySelectorAll('.button_bordered, .human-id__button');
        for (let i = 0; i < navigationButtons.length; i++) {
            navigationButtons[i].onclick = function() {
                clearTimeout(userInactivityTimer);
            };
        }

        const credentials = { number: succeededResponse?.reportId?.toString(), pin: succeededResponse?.pin };

        if (shouldBeTranslated) {
            storageHelper?.setMessageCredentials(credentials);
        }

        dispatch(setCredentials(credentials));

        return () => {
            dispatch(clearSucceededResponse());

            if (shouldBeTranslated) {
                changeLanguage(getLanguage().code)
            }
        }
    }, [])

    return succeededResponse && (
        <>
            <div className='report-succeeded__container tab-bar-predecessor'>
                <div className="report-succeeded__logo" />
                <div className='main'>
                    <div className='report-succeeded__success-icon' />
                    <div className='html-markup__container'>
                        <div className='report-succeeded__thank-you-text text_enlarged' 
                            dangerouslySetInnerHTML={{ __html: succeededResponse.ThankYouText } || 'Thank You'} />
                    </div>
                </div>
                <div className='report-succeeded__navigation-container'>
                    {userHumanId 
                        ? <Link to={routes.messages} className='button_bordered report-succeeded__messages-button'>
                            View Messages
                        </Link>
                        : <Link to={routes.messages} className='button_bordered report-succeeded__messages-button'>
                            Continue to View Messages
                        </Link>
                    }
                    <Link to={routes.home} className='button_bordered report-succeeded__home-button'>Return to the main screen</Link>
                    {userHumanId && 
                        (<div className='report-succeeded__download-apps-icons__container'>
                            <AndroidAppLink />
                            <IosAppLink />
                        </div>)
                    }
                </div>
            </div>
        </>
    )
}