import React, {  useState } from 'react'
import TabBar from '../../common/TabBar'
import Header from '../../common/Header'
import { Link } from 'react-router-dom'
import { routes } from '../../../routing/routeConsts'
import { getLanguage } from '../../../helpers/languageHelper'
import { authHelper } from '../../../api/authHelper'
import Modal from '../../common/modals/Modal';

export default function OptionsPage() {
    const [showWarning, setShowWarning] = useState(false);

    const logoutOfHumanId = () => {
        authHelper.clearUserHumanId();
        setShowWarning(true);
    }

    return (
        <>
            {showWarning &&
                <Modal
                    onCancelClick={() => {
                        setShowWarning(false);
                    }}
                    text={"You have been successfully logged out"}
                    cancelText="Okay"
                />}

            <Header title="More" />
            <div className="container tab-bar-predecessor">
                <Link className="container_bordered" to={routes.settings}>
                    <div className="options__icon options__icon_settings"></div>
                    <div className="text_primary" >Settings</div>
                </Link>
                <a className="container_bordered" href="https://reportit.com/support/" target="_blank" rel='noreferrer'>
                    <div className="options__icon options__icon_contact"></div>
                    <div className="text_primary">Contact Us</div>
                </a>
                <a className="container_bordered" href="https://reportit.com/privacy-policy" target="_blank" rel='noreferrer'>
                    <div className="options__icon options__icon_terms"></div>
                    <div className="text_primary" >Privacy Policy</div>
                </a>
                <a className="container_bordered" href="https://reportit.com/terms" target="_blank" rel='noreferrer'>
                    <div className="options__icon options__icon_terms"></div>
                    <div className="text_primary" >Terms of Use</div>
                </a>
                <Link className="container_bordered" to={routes.aboutUs}>
                    <div className="options__icon options__icon_about"></div>
                    <div className="text_primary" >About Us</div>
                </Link>
                <a className="container_bordered" href="https://reportit.com/home" target="_blank" rel='noreferrer'>
                    <div className="options__icon options__icon_website"></div>
                    <div className="text_primary" >Corporate Website</div>
                </a>
                <Link className="container_bordered" to={routes.languages}>
                    <img className="options__icon" src={getLanguage().b64String} alt="language" />
                    <div className="text_primary" >Language</div>
                </Link>
            </div>
            <TabBar />
        </>
    )
}